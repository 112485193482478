//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
	document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + '　宅地建物取引士　埼玉県 法定講習会センター';
};


// ヘッダー動作（下にスクロールで隠れて、上にスクロールで再表示する）
var startPos = 0;
$(window).scroll(function(){
	var currentPos = $(this).scrollTop();
	var w = $(window).width();
	if (w > 768) {
		if (currentPos > startPos) {
			if($(window).scrollTop() >= 100) {
				$("#header").css("top", "-" + 168 + "px");
			}
		} else {
			$("#header").css("top", 0 + "px");
			$("#header").css("transition", .3 + "s");
		}
		startPos = currentPos;
	}
});


// ハンバーガーメニュー
$(function(){
	$('#js_GNav__menuBtn').on("click", function(){
		$(this).toggleClass('-open');
	});
});


// タブ
$(function() {
	$(".js_tab .tabTitle").click(function() {
		var num = $(".js_tab .tabTitle").index(this);
		$(".tabContent").removeClass('active');
		$(".tabContent").eq(num).addClass('active');
		$(".js_tab .tabTitle").removeClass('active');
		$(this).addClass('active')
	});
});


// スムーズスクロール
$(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href = $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});


// マウスホバー時に開くアコーディオンメニュー
$(function(){
	$('.js_pullDownPc').hover(function() {
		var w = $(window).width();
		if (w > 768) {
			$(this).toggleClass('-active');
			$(this).children('.js_pullDownContentPc').stop().slideToggle();
		}
	});
});


// トグル
$(function(){
	$('.js_toggleBtn').click(function() {
		$(this).toggleClass('-active');
		$(this).children('.js_togglContents').slideToggle();
	});
});


// トグル（スマホ限定）
$(function(){
	$('.js_toggleBtnSp').click(function() {
		var w = $(window).width();
		if (w <= 768) {
			$(this).toggleClass('-active');
			$(this).children('.js_togglContentsSp').slideToggle();
		}
	});
});

